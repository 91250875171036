import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  chatMessagesReceived,
  endTokenReceived,
  eventReceived,
  initializeChat,
  newMessageReceived,
  openChatWindow,
  ratingAdded,
  reloadPageReceived,
  returnButtonReceived,
  sendCommand,
  streamTokenReceived,
  taskCreatedReceived,
  toggleChatWindow,
  waitingPaymentReceived,
  waitingReceived,
  welcomeMessageReceived,
} from '../actions/ai-widget.actions';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { SocketIoService } from '../../app-common/services/socket.io/socket.io.service';
import { SOCKET_NAME_AI_CHAT } from '@ipnote/const';
import { AiRequestType, AiResponseType } from '@ipnote/type';
import { AiChatRequestCommandEnum, AiChatResponseCommandEnum } from '@ipnote/enum';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';
import { selectStateAiWidgetChat } from '#selectors';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class AiWidgetEffects {
  private socketInitialized = false;
  private commandsWithData: AiChatRequestCommandEnum[] = [
    AiChatRequestCommandEnum.START,
    AiChatRequestCommandEnum.DISCUSSION,
  ];

  constructor(private actions$: Actions, private socketService: SocketIoService, private store: Store<AppState>) {}

  ConnectToChat$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(initializeChat),
        map(async (res) => {
          await this.initializeSocket();
          this.socketService.socket.on(SOCKET_NAME_AI_CHAT, async (res: AiResponseType) => {
            const { command } = res;

            switch (command) {
              case AiChatResponseCommandEnum.WELCOME:
                this.store.dispatch(welcomeMessageReceived(res.message));
                break;
              case AiChatResponseCommandEnum.NEW_MESSAGE:
                this.store.dispatch(newMessageReceived(res.message));
                break;
              case AiChatResponseCommandEnum.STREAM_TOKEN:
                this.store.dispatch(streamTokenReceived({ token: res.token }));
                break;
              case AiChatResponseCommandEnum.WAITING:
                this.store.dispatch(waitingReceived({ operation: res.operation }));
                break;
              case AiChatResponseCommandEnum.RETURN_BTN:
                this.store.dispatch(returnButtonReceived({ buttons: res.buttons }));
                break;
              case AiChatResponseCommandEnum.END_TOKEN:
                this.store.dispatch(endTokenReceived());
                break;
              case AiChatResponseCommandEnum.CHAT_MESSAGES:
                this.store.dispatch(chatMessagesReceived({ messages: res.messages, chatId: res.chatId }));
                break;
              case AiChatResponseCommandEnum.WAITING_PAYMENT:
                this.store.dispatch(waitingPaymentReceived({ serviceType: res.serviceType }));
                break;
              case AiChatResponseCommandEnum.CREATED_TASK:
                this.store.dispatch(taskCreatedReceived({ taskId: res.taskId }));
                break;
              case AiChatResponseCommandEnum.EVENT:
                this.store.dispatch(eventReceived({ eventName: res.eventName }));
                break;
              case AiChatResponseCommandEnum.RATING_ADDED:
                this.store.dispatch(ratingAdded({ isGood: res.isGood }));
                break;
              case AiChatResponseCommandEnum.RELOAD_PAGE:
                this.store.dispatch(reloadPageReceived());
                break;
            }
          });
        }),
      ),
    {
      dispatch: false,
    },
  );

  openChatWindow$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openChatWindow, toggleChatWindow),
        withLatestFrom(this.store.select(selectStateAiWidgetChat)),
        tap(async ([action, chatState]) => {
          if (!chatState.initialized) {
            this.store.dispatch(initializeChat());
          }
        }),
        // filter(([_, messages]) => messages.messages.length === 0),
        tap(async () => {
          await this.sendCommand(AiChatRequestCommandEnum.INIT);
        }),
      ),
    { dispatch: false },
  );

  SendChatCommand$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(sendCommand),
        // filter(([_, messages]) => messages.messages.length === 0),
        map(async (action) => {
          if (this.commandsWithData.includes(action.command)) {
            await this.send(action);
          } else {
            await this.sendCommand(action.command);
          }
        }),
      ),
    { dispatch: false },
  );

  private async initializeSocket() {
    if (!this.socketInitialized) {
      this.socketService.setupSocketConnection();
      await this.ensureSocketInitialized();
      this.socketInitialized = true;
    }
  }

  private async ensureSocketInitialized() {
    return new Promise<void>((resolve) => {
      const checkSocket = () => {
        if (this.socketService.socket) {
          resolve();
        } else {
          setTimeout(checkSocket, 100);
        }
      };
      checkSocket();
    });
  }

  private async sendCommand(command: AiChatRequestCommandEnum) {
    await this.ensureSocketInitialized();
    if (this.socketService.socket) {
      this.socketService.socket.emit(SOCKET_NAME_AI_CHAT, {
        command,
        chatId: null,
      });
    } else {
      console.error('Socket is not initialized');
    }
  }

  private async send(data: AiRequestType) {
    await this.ensureSocketInitialized();
    if (this.socketService.socket) {
      this.socketService.socket.emit(SOCKET_NAME_AI_CHAT, data);
    } else {
      console.error('Socket is not initialized');
    }
  }
}
