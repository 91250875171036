<div [@fadeInUp] class="basis-[381px] overflow-hidden flex flex-col items-center">
  <div class="flex flex-col items-center">
    <img class="w-[150px] h-[42px]" src="assets/img/new-ipnote-logo.png" alt=""/>
    <div class="text-center my-6">AI powered IP revolution - IP made 5x cheaper and easier</div>
  </div>

  <div class="rounded w-fullblock">
    <div class="flex items-center justify-center m-8"
         *ngIf="(confirmEmailStore$ | async).waitingServerResponse"
    >
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>

    <div>
      <div class="">
        <h2 class="font-bold text-lg"
            *ngIf='(confirmEmailStore$ | async)?.serverRes?.status === "success"'>{{(confirmEmailStore$ | async)?.serverRes?.name}}
          , {{ "hello" | transloco }}!</h2>
        <h2 class="font-bold text-lg" *ngIf="(confirmEmailStore$ | async)?.error">{{ "hello" | transloco }}
          !</h2>
      </div>
      <div class="mt-2">
        <h4 class="body-2 text-secondary m-0"
            *ngIf='(confirmEmailStore$ | async)?.serverRes?.status === "success"'>Your email address has been verified. You can continue using IPNOTE</h4>
        <h4 class="body-2 text-secondary m-0"
            *ngIf="(confirmEmailStore$ | async)?.error">{{ "confirm_email_expired_message" | transloco }}</h4>
      </div>
      <div class="text-center mt-6">
        <button *ngIf='(confirmEmailStore$ | async)?.serverRes?.status === "expired"' [routerLink]="getLink() | async"
                color="primary" mat-raised-button type="button"
                class="mb-10 w-full">Link expired
        </button>
        <button *ngIf='(confirmEmailStore$ | async)?.serverRes?.status === "success"' [routerLink]="getLink() | async"
                color="primary" mat-raised-button type="button"
                class="mb-10 w-full">{{ "CONTINUE USING" | transloco }}</button>
        <button *ngIf="(confirmEmailStore$ | async)?.error" [routerLink]="['/auth/sign-up']" color="primary"
                mat-raised-button type="button"
                class="mb-10 w-full">{{ "create-account" | transloco }}</button>
      </div>
    </div>
  </div>
</div>
