import { createReducer, on } from '@ngrx/store';
import * as TasksTabActions from '../actions/tasks-tab.actions';

export interface TasksTab {
  view: string | null;
}

export const initialState: TasksTab = {
  view: null,
};

export const tasksTabReducer = createReducer(
  initialState,
  on(TasksTabActions.setActiveTasksTab, (state, { view }) => ({
    ...state,
    view,
  })),
);
