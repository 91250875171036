export enum AiToolNameEnum {
  CREATE_TASK = 'create_task',
  OFFER_REQUEST = 'offer_request',
  TRADEMARK_SEARCH = 'trademark_search',
  TRADEMARK_SEARCH_NUMBER = 'trademark_search_number',
  WEBSITE_SCRAPER_TOOL = 'website_scraper_tool',
  PATENT_SEARCH = 'patent_search',
  PATENT_SEARCH_NUMBER = 'patent_search_number',
  PATENT_FEES = 'patent_fees',
  PATENT_FEES_PARAMS = 'patent_fees_params',
  SIGN_UP = 'sign_up',
  CODE_VERIFY = 'code_verify',
  RESEND_CODE_VERIFY = 'resend_code_verify',
  PROVIDER_SELECTION = 'provider_selection',
  OFFER_SELECTION = 'offer_selection',
  OFFER_PROVIDER_NUMBER = 'offer_provider_number',
  TASKS_OVERVIEW = 'tasks_overview',
  TASK_CONFIRMATION = 'task_confirmation',
  OIP_CHECK = 'oip_check',
}
