import { createReducer, on } from '@ngrx/store';
import * as ChatAiActions from '../../actions/ai-widget.actions';
import { sendCommand } from '../../actions/ai-widget.actions';

export interface AiWidgetChatState {
  initialized: boolean;
  messages: any[];
  waiting: boolean;
  chatMode: 'AI_CHAT' | 'REGISTRATION_CHAT';
  waitingOperation: string | null;
  waitingPayment: { status: boolean; type: string | null };
  chatId: number | null;
  streamToken: string | null;
  hints: string[];
}

export const initialState: AiWidgetChatState = {
  initialized: false,
  messages: [],
  waiting: false,
  chatMode: 'AI_CHAT',
  waitingOperation: null,
  waitingPayment: { status: false, type: null },
  chatId: null,
  streamToken: null,
  hints: [],
};

export const AiWidgetChatReducer = createReducer(
  initialState,
  on(ChatAiActions.initializeChat, (state) => ({
    ...state,
    initialized: true,
    waiting: true,
  })),
  on(ChatAiActions.sendCommand, (state) => ({
    ...state,
    waiting: true,
    waitingOperation: 'Processing the request',
    hints: [],
  })),
  on(ChatAiActions.welcomeMessageReceived, (state, message) => ({
    ...state,
    waiting: false,
    waitingOperation: null,
    messages: [...state.messages, message],
  })),
  on(ChatAiActions.newMessageReceived, (state, message) => ({
    ...state,
    messages: [...state.messages, message],
  })),
  on(ChatAiActions.streamTokenReceived, (state, { token }) => {
    const lastMessage = state.messages[state.messages.length - 1];
    const updatedLastMessage = {
      ...lastMessage,
      content: lastMessage.content + token,
    };
    return {
      ...state,
      messages: [...state.messages.slice(0, state.messages.length - 1), updatedLastMessage],
      streamToken: token,
    };
  }),
  on(ChatAiActions.endTokenReceived, (state) => ({
    ...state,
    waiting: false,
    waitingOperation: null,
    streamToken: null,
  })),
  on(ChatAiActions.waitingReceived, (state, { operation }) => ({
    ...state,
    waiting: true,
    waitingOperation: operation,
  })),
  on(ChatAiActions.chatMessagesReceived, (state, { messages, chatId }) => ({
    ...state,
    messages: [...state.messages, ...messages],
    chatId: chatId,
  })),
  on(ChatAiActions.waitingPaymentReceived, (state, { serviceType }) => ({
    ...state,
    waitingPayment: { status: true, type: serviceType },
  })),
  on(ChatAiActions.taskCreatedReceived, (state, { taskId }) => ({
    ...state,
    taskCreated: true,
  })),
  on(ChatAiActions.eventReceived, (state, { eventName }) => ({
    ...state,
    // Обработка события, если необходимо
  })),
  on(ChatAiActions.ratingAdded, (state, { isGood }) => ({
    ...state,
    // Добавление рейтинга к сообщению, если необходимо
  })),
  on(ChatAiActions.reloadPageReceived, (state) => ({
    ...state,
    reloadRequired: true,
  })),
  on(ChatAiActions.returnButtonReceived, (state, { buttons }) => ({
    ...state,
    returnButtons: buttons,
  })),
);
