import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';
import { SchedulerService } from './scheduler/scheduler.service';
import { CompanyAllReset } from './store/actions/company.actions';
import { RouterEventsService } from './app-common/services/router-events/router-events.service';
import { ListenSseSubscriptionService } from './app-common/services/server-sent-events/listen-sse-subscription.service';
import { UserGetKeyThrottler } from './store/actions/user.actions';


@Injectable({
  providedIn: 'root'
})
export class AppInitService {

  constructor(
    private store: Store<AppState>,
    private scheduler: SchedulerService,
    private routerEvents: RouterEventsService,
    private listenSseSubscription: ListenSseSubscriptionService
  ) {
  }

  init(): void {
    this.store.dispatch(CompanyAllReset());
    this.scheduler.startScheduler();
    this.routerEvents.startRouterSetStore();
    this.store.dispatch(UserGetKeyThrottler());
    // this.listenSseSubscription.listen().then(r => console.log(r));
  }

}
