import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import {
  UserCheckBackUrl,
  UserConfirmEmail,
  UserConfirmEmailError,
  UserConfirmEmailSuccess,
  UserForgotPassword,
  UserForgotPasswordConfirm,
  UserForgotPasswordConfirmError,
  UserForgotPasswordConfirmSuccess,
  UserForgotPasswordError,
  UserForgotPasswordSuccess,
  UserGetInfo,
  UserGetKeyThrottler,
  UserProfileLoad,
  UserProfileLoadSuccess,
  UserProfileUpdate,
  UserProfileUpdateEmail,
  UserProfileUpdateEmailError,
  UserProfileUpdateEmailSuccess,
  UserProfileUpdateError,
  UserProfileUpdatePassword,
  UserProfileUpdatePasswordError,
  UserProfileUpdatePasswordSuccess,
  UserProfileUpdateSuccess,
  UserRedirectBackUrl,
  UserSetBackUrl,
  UserSetData,
  UserSetKeyThrottler,
  UserSignIn,
  UserSignInAdmin,
  UserSignInError,
  UserSignInGoogle,
  UserSignInGoogleCallback,
  UserSignInLinkedIn,
  UserSignInLinkedInCallback,
  UserSignInSuccess,
  UserSignOut,
  UserSignUp,
  UserSignUpError,
  UserSignUpSuccess,
} from '../actions/user.actions';
import { catchError, concatMap, filter, finalize, flatMap, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  IUserEmailConfirmationRes,
  IUserEntity,
  IUserMeRes,
  IUserSignInRes,
  IUserSignInResDto,
  IUserSignInSocialResDto,
  IUserSignUpResDto,
} from '@ipnote/interface';
import { Observable, of } from 'rxjs';
import { AppState } from '#appState';
import { BaseUrlService } from 'app/app-common/services/base-url/base-url.service';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { ErrorResponseCreate } from '@ipnote/shared';
import * as Sentry from '@sentry/angular';
import { selectStateIsAuth, selectStateUserInfo } from '#selectors';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import {
  CompanyAllReset,
  CompanyCreateRedirect,
  CompanySelect,
  CompanySelectReset,
  CompanySetData,
} from '../actions/company.actions';
import { TypedAction } from '@ngrx/store/src/models';
import { AnalyticsService } from 'app/app-common/services/analytics/analytics.service';
import { routerSelector } from '../selectors/router.selectors';
import { StateRouter } from '../reducers/router.reducers';
import { DialogService } from 'app/app-common/services/dialogs/dialog.service';
import { selectStateUser } from '../selectors/user.selectors';
import { AnalyticsEventEnum } from '@ipnote/enum';
import { environment } from '../../../environments/environment';
import { CompaniesService } from '../../page-modules/desk/services/companies/companies.service';
import { SpinnerViewService } from '../../app-common/services/spinner/spinner-view.service';

@Injectable({ providedIn: 'root' })
export class UserEffects {
  /** *
   * SignIn
   */
  signIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserSignIn),
      mergeMap((payload) => {
        return this.baseUrl.getAuthBaseUrl('sign-in').pipe(
          mergeMap((url) => this.http.post<IUserSignInResDto>(url, payload)),
          map((user) => UserSignInSuccess({ user, reboot: true })),
          catchError((error: ErrorResponseCreate) => of(UserSignInError({ error }))),
        );
      }),
    ),
  );

  signInGoogle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserSignInGoogle),
        tap((p) => (window.location.href = environment.baseUrl + '/sign-in/google')),
      ),
    {
      dispatch: false,
    },
  );

  signInGoogleCallback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserSignInGoogleCallback),
      mergeMap((payload) => {
        return this.baseUrl.getAuthBaseUrl('sign-in/google/callback').pipe(
          mergeMap((url) => {
            this.spinnerService.start();
            return this.http.get<IUserSignInSocialResDto>(url, {
              params: {
                ...payload.params,
              },
              withCredentials: true,
            });
          }),
          map((user) => {
            if (user.newRegistration) {
              this.analyticsService.sendEvent(AnalyticsEventEnum.SIGN_UP, { user: user.user });
            }
            return UserSignInSuccess({ user, reboot: true });
          }),
          finalize(() => this.spinnerService.stop()),
          catchError((error: ErrorResponseCreate) => of(UserSignInError({ error }))),
        );
      }),
    ),
  );

  signInLinkedIn$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserSignInLinkedIn),
        tap((p) => (window.location.href = environment.baseUrl + '/sign-in/linked-in')),
      ),
    {
      dispatch: false,
    },
  );

  signInLinkedInCallback$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserSignInLinkedInCallback),
      mergeMap((payload) => {
        return this.baseUrl.getAuthBaseUrl('sign-in/linked-in/callback').pipe(
          flatMap((url) => {
            this.spinnerService.start();
            return this.http.get<IUserSignInSocialResDto>(url, {
              params: {
                ...payload.params,
              },
              withCredentials: true,
            });
          }),
          map((user) => {
            if (user.newRegistration) {
              this.analyticsService.sendEvent(AnalyticsEventEnum.SIGN_UP, { user: user.user });
            }

            return UserSignInSuccess({ user, reboot: true });
          }),
          finalize(() => this.spinnerService.stop()),
          catchError((error: ErrorResponseCreate) => of(UserSignInError({ error }))),
        );
      }),
    ),
  );

  signInAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserSignInAdmin),
      mergeMap((payload) => {
        return this.baseUrl.getAuthBaseUrl('sign-in/administrator').pipe(
          flatMap((url) => this.http.post<IUserSignInResDto>(url, payload.user)),
          map((user) => {
            this.store.dispatch(CompanyAllReset());
            this.store.dispatch(CompanySelectReset());
            return UserSignInSuccess({ user, reboot: true });
          }),
          catchError((error: ErrorResponseCreate) => of(UserSignInError({ error }))),
        );
      }),
    ),
  );

  signInSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserSignInSuccess),
      mergeMap((action) => {
        Sentry.setUser({
          ...action.user.user,
          id: String(action.user.user.id),
        });
        this.analyticsService.sendEvent(AnalyticsEventEnum.SIGN_IN, {}).then();

        const returnActions: TypedAction<any>[] = [];
        /** Если есть компания пишем ее, и там все перенаправления */
        if (action.user.company) {
          returnActions.unshift(
            CompanySelect({ company: action.user.company, isAuth: true, thisIsNew: false, reloadPage: action.reboot }),
          );
        } else {
          returnActions.unshift(CompanySelectReset(), CompanyCreateRedirect());
        }

        return returnActions;
      }),
    ),
  );

  /***
   * SignUp
   */
  signUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserSignUp),
      mergeMap((payload) => {
        return this.baseUrl.getAuthBaseUrl('sign-up').pipe(
          mergeMap((url) => this.http.post<IUserSignUpResDto>(url, payload.user, { withCredentials: true })),
          map((result) => UserSignUpSuccess({ payload: payload.user, userResponse: result })),
          catchError((error: ErrorResponseCreate) => of(UserSignUpError({ error }))),
        );
      }),
    ),
  );

  signUpSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserSignUpSuccess),
        map((action) => {
          this.analyticsService
            .sendEvent(AnalyticsEventEnum.SIGN_UP, {
              user: action.payload,
              company: action.payload.company,
            })
            .then();
          this.store.dispatch(CompanySetData({ company: action.userResponse.company }));
          if (!action.userResponse.company.isServiceProvider) {
            this.router
              .navigate(['/ai-create-task'], {
                queryParams: {},
              })
              .then();
          } else {
            this.store.dispatch(
              CompanySelect({
                company: action.userResponse.company,
                isAuth: true,
                thisIsNew: false,
                reloadPage: true,
              }),
            );
          }
        }),
      ),
    {
      dispatch: false,
    },
  );

  /***
   * SignOut
   */
  signOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserSignOut),
        map(() => {
          this.analyticsService.sendEvent(AnalyticsEventEnum.SIGN_OUT, {});
          if (!this.routerStore.urlCurrent.includes('public-page') && !this.router.url.includes('ai')) {
            this.router.navigateByUrl('/auth/log-in');
          }
        }),
      ),
    {
      dispatch: false,
    },
  );

  /***
   * Confirm email
   */
  confirmEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserConfirmEmail),
      mergeMap(({ data }) => {
        return this.baseUrl.getAuthBaseUrl('email/confirmation').pipe(
          flatMap((url) => this.http.post<IUserEmailConfirmationRes>(url, data)),
          map((result) => {
            this.analyticsService.sendEvent(AnalyticsEventEnum.CONFIRM_EMAIL, {});
            return UserConfirmEmailSuccess({ result });
          }),
          catchError((error: ErrorResponseCreate) => of(UserConfirmEmailError({ error }))),
        );
      }),
    ),
  );

  /***
   * Forgot password
   */
  forgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserForgotPassword),
      mergeMap((payload) => {
        return this.baseUrl.getAuthBaseUrl('password/recovery').pipe(
          flatMap((url) => this.http.post<boolean>(url, { email: payload.email })),
          map((result) => UserForgotPasswordSuccess()),
          catchError((error: ErrorResponseCreate) => of(UserForgotPasswordError({ error }))),
        );
      }),
    ),
  );
  forgotPasswordConfirm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserForgotPasswordConfirm),
      mergeMap((payload) => {
        return this.baseUrl.getAuthBaseUrl('password/recovery-confirm').pipe(
          flatMap((url) => this.http.post<IUserSignInResDto>(url, payload.data)),
          map((result) => UserForgotPasswordConfirmSuccess({ user: result })),
          catchError((error: ErrorResponseCreate) => of(UserForgotPasswordConfirmError({ error }))),
        );
      }),
    ),
  );
  forgotPasswordConfirmSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserForgotPasswordConfirmSuccess),
      map((result) => UserSignInSuccess({ user: result.user, reboot: true })),
    ),
  );

  /***
   * User get info
   */
  userGetInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserGetInfo),
      mergeMap((payload) => {
        return this.baseUrl.getAuthBaseUrl('users/info').pipe(
          flatMap((url) => this.http.get<IUserSignInRes>(url)),
          map((user) => UserSetData({ user })),
        );
      }),
    ),
  );

  /***
   * User get key throttler
   */
  userGetKeyThrottler$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserGetKeyThrottler),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store.select(selectStateUser)))),
      filter(([action, { isAuthenticated }]) => !isAuthenticated),
      mergeMap(() => {
        return this.baseUrl.getAuthBaseUrl('key-throttler').pipe(
          flatMap((url) => this.http.get(url, { responseType: 'text', withCredentials: true })),
          map((key) => UserSetKeyThrottler({ key })),
        );
      }),
    ),
  );

  /***
   * User get back url
   */
  UserCheckBackUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserCheckBackUrl),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store.select(selectStateIsAuth)))),
      filter(([ac, isAuth]) => !isAuth),
      map(() => {
        let url = null;
        if (!this.routerStore.urlCurrent.includes('public-page')) {
          if (this.routerStore.urlPrev !== null && !this.excludedUrl()) {
            url = this.routerStore.urlPrev;
          }
        }
        return UserSetBackUrl({ url });
      }),
    ),
  );

  /***
   * User get back url
   */
  UserRedirectBackUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserRedirectBackUrl),
      map(({ url }) => {
        this.router.navigateByUrl(url);
        return UserSetBackUrl({ url: null });
      }),
    ),
  );

  /***
   * User profile
   */
  UserProfileLoad$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserProfileLoad),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store.select(selectStateUserInfo)))),
      mergeMap(([action, { id }]) => {
        return this.baseUrl.getBaseUrl(`users/${id}`).pipe(
          flatMap((url) => this.http.get<IUserMeRes>(url)),
          map((userProfile) => UserProfileLoadSuccess({ userProfile })),
        );
      }),
    );
  });
  UserProfileUpdateEmail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserProfileUpdateEmail),
      mergeMap((action) => {
        return this.baseUrl.getAuthBaseUrl('email/change').pipe(
          flatMap((url) => {
            return this.http.put<{ success: boolean }>(url, action.payload);
          }),
          map((res) => {
            if (res.success) {
              return UserProfileUpdateEmailSuccess({ payload: action.payload });
            }
          }),
          catchError((error) => of(UserProfileUpdateEmailError({ error }))),
        );
      }),
    );
  });

  UserProfileUpdateEmailError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserProfileUpdateEmailError),
        map((payload) => {
          this.dialogs.error(payload.error);
        }),
      ),
    { dispatch: false },
  );

  UserProfileUpdatePassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserProfileUpdatePassword),
      mergeMap((action) => {
        return this.baseUrl.getAuthBaseUrl('password/change').pipe(
          flatMap((url) => {
            return this.http.put<{ success: boolean }>(url, action.payload);
          }),
          map((res) => UserProfileUpdatePasswordSuccess()),
          catchError((error) => of(UserProfileUpdatePasswordError({ error }))),
        );
      }),
    );
  });

  UserProfileUpdatePasswordError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserProfileUpdatePasswordError),
        map((payload) => {
          this.dialogs.error(payload.error);
        }),
      ),
    { dispatch: false },
  );

  UserProfileUpdate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserProfileUpdate),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store.select(selectStateUserInfo)))),
      mergeMap(([action, userInfo]) => {
        return this.baseUrl.getBaseUrl(`users/${userInfo.id}`).pipe(
          mergeMap((url) => this.http.put<IUserEntity>(url, action.payload)),
          map((res) => UserProfileUpdateSuccess()),
          catchError((error) => of(UserProfileUpdateError())),
        );
      }),
    );
  });
  UserProfileUpdateSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserProfileUpdateSuccess),
        // tap(() => {
        //   this.snackBar.open(this.translocoService.translate('profile-is-saved'), this.translocoService.translate('ok'), {
        //     duration: 2000
        //   });
        // }),
        map(() => this.store.dispatch(UserGetInfo())),
      );
    },
    {
      dispatch: false,
    },
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private baseUrl: BaseUrlService,
    private http: HttpClient,
    private store: Store<AppState>,
    private matDialog: MatDialog,
    private snackBar: MatSnackBar,
    private dialogs: DialogService,
    private translocoService: TranslocoService,
    private analyticsService: AnalyticsService,
    private companiesService: CompaniesService,
    private spinnerService: SpinnerViewService,
  ) {
    this.routerStore$.subscribe((router) => {
      this.routerStore = router;
    });
  }

  public routerStore$: Observable<StateRouter> = this.store.select(routerSelector);
  public routerStore: StateRouter;

  private excludedUrl(): boolean {
    const excludedUrl = ['auth'];
    return !!excludedUrl.find((url) => this.routerStore.urlPrev.includes(url));
  }
}
