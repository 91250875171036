<div @fadeInUp class="">
  <div class="flex flex-col items-center mt-[40px] sm:mt-0">
    <img class="w-[150px] h-[42px]" src="assets/img/new-ipnote-logo.png" alt=""/>
    <div class="text-center my-6">AI powered IP revolution - IP made 5x cheaper and easier</div>
  </div>
  <div *ngIf="step === enums.publicPageSteps.CHOICE_COMPANY_TYPE" class="choice-company-type-form">
    <mat-radio-group color="primary" class="flex space-x-2" [(ngModel)]="companyType">
      <div [ngClass]="{'blue-border': companyType === enums.companyTypeEnum.CUSTOMER}"
           (click)="choiceType(enums.companyTypeEnum.CUSTOMER)"
           class="cursor-pointer basis-1/2 border-2 border-designSystem-iceberg rounded-[4px]  h-[190px] flex items-center justify-center relative">
        <mat-radio-button class="absolute top-3 right-1" [value]="enums.companyTypeEnum.CUSTOMER"></mat-radio-button>
        <span class="text-center font-medium">I need IP assistance<br>as a customer</span>
      </div>
      <div (click)="choiceType(enums.companyTypeEnum.PROVIDER)"
           [ngClass]="{'blue-border': companyType === enums.companyTypeEnum.PROVIDER}"
           class="cursor-pointer  basis-1/2 border-2 border-designSystem-iceberg rounded-[4px] h-[190px] flex items-center justify-center relative">
        <mat-radio-button class="absolute top-3 right-1" [value]="enums.companyTypeEnum.PROVIDER"></mat-radio-button>
        <span class="text-center font-medium">I offer IP service<br>as a provider</span>
      </div>
    </mat-radio-group>

    <button (click)="step=enums.publicPageSteps.PERSONAL_FIELD" [disabled]="!companyType"
            class="w-full mt-4 join-button h-[40px]"
            id="select_is_provider_signup" mat-raised-button color="primary">{{ joinButtonText }}</button>
    <div class="confirm-conditions mt-4"
         [innerHTML]="'confirm_conditions' | transloco | safe:'html'"></div>

<!--    <app-idp-sign-in></app-idp-sign-in>-->

    <div class="mt-4">
      <span class="pr-1 text-sm">{{ "already-have-an-account" | transloco }}</span>
      <a class="text-sm" [routerLink]="['/auth/log-in']">{{ "sign-in-here" | transloco }}</a>
    </div>
  </div>
  <app-personal-field *ngIf="step===enums.publicPageSteps.PERSONAL_FIELD" (email)="email=$event"
                      [companyType]="companyType"
                      (companyTypeHandler)="changeCompanyType($event)">
  </app-personal-field>
</div>
