import * as user from './reducers/user/index';
import { ActionReducerMap } from '@ngrx/store';

export interface StateUser {
  user: user.StateUser | null;
  userProfile: user.StateUserProfile;
  signUp: user.StateSignUp | null;
  signIn: user.StateSignIn | null;
  confirmEmail: user.StateConfirmEmail | null;
  forgotPassword: user.StateForgotPassword | null;
}

export const reducers: ActionReducerMap<StateUser> = {
  user: user.userReducerDefault,
  userProfile: user.userProfileReducerDefault,
  signUp: user.signUpReducerDefault,
  signIn: user.signInReducerDefault,
  confirmEmail: user.confirmEmailReducerDefault,
  forgotPassword: user.forgotPasswordReducerDefault
};
