import { createReducer, on } from '@ngrx/store';
import * as AiWidgetActions from '../../actions/ai-widget.actions';

export interface AiWidgetWindowState {
  isChatWindowOpen: boolean;
  isNotifyWindowOpen: boolean;
  notificationCount: number;
}

export const initialState: AiWidgetWindowState = {
  isChatWindowOpen: false,
  isNotifyWindowOpen: false,
  notificationCount: 0,
};

export const aiWidgetWindowReducer = createReducer(
  initialState,
  on(AiWidgetActions.openChatWindow, (state) => ({ ...state, isChatWindowOpen: true })),
  on(AiWidgetActions.closeChatWindow, (state) => ({ ...state, isChatWindowOpen: false })),
  on(AiWidgetActions.toggleChatWindow, (state) => ({ ...state, isChatWindowOpen: !state.isChatWindowOpen })),
  on(AiWidgetActions.openNotifyWindow, (state) => ({ ...state, isNotifyWindowOpen: true })),
  on(AiWidgetActions.closeNotifyWindow, (state) => ({ ...state, isNotifyWindowOpen: false })),
  on(AiWidgetActions.toggleNotifyWindow, (state) => ({ ...state, isNotifyWindowOpen: !state.isNotifyWindowOpen })),
  on(AiWidgetActions.updateNotificationCount, (state, { count }) => ({ ...state, notificationCount: count })),
);
