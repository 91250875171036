import { RIAType } from './ria-type.enum';
import { RIASubType } from './ria-subtype.enum';

export const RIATypesRelations = {
  [RIAType.TECHNICAL_SOLUTIONS]: {
    value: RIAType.TECHNICAL_SOLUTIONS,
    name: RIAType.TECHNICAL_SOLUTIONS,
    subTypes: [RIASubType.WAY, RIASubType.DEVICE, RIASubType.SUBSTANCE, RIASubType.APPLICATION],
  },
  [RIAType.IDENTIFICATION]: {
    value: RIAType.IDENTIFICATION,
    name: RIAType.IDENTIFICATION,
    subTypes: [RIASubType.LOGO, RIASubType.NAMING, RIASubType.DOMAIN_NAME],
  },
  [RIAType.DESIGN]: {
    value: RIAType.DESIGN,
    name: RIAType.DESIGN,
    subTypes: [RIASubType.PRODUCT, RIASubType.PACKAGING, RIASubType.INTERFACE],
  },
  [RIAType.PROGRAM]: {
    value: RIAType.PROGRAM,
    name: RIAType.PROGRAM,
    subTypes: [RIASubType.ALGORITHM, RIASubType.SOURCE_CODE],
  },
  [RIAType.DOCUMENTATION]: {
    value: RIAType.DOCUMENTATION,
    name: RIAType.DOCUMENTATION,
    subTypes: [RIASubType.DESIGN_DOCUMENTATION, RIASubType.PROJECT_DOCUMENTATION, RIASubType.TECHNICAL_DOCUMENTATION],
  },
  [RIAType.BUSINESS_SOLUTION]: {
    value: RIAType.BUSINESS_SOLUTION,
    name: RIAType.BUSINESS_SOLUTION,
    subTypes: [RIASubType.IDEA, RIASubType.PROJECT, RIASubType.METHOD],
  },
  [RIAType.CONTENT]: {
    value: RIAType.CONTENT,
    name: RIAType.CONTENT,
    subTypes: [RIASubType.VIDEO, RIASubType.AUDIO, RIASubType.PHOTO, RIASubType.TEXT],
  },
};
