export enum AiChatResponseWaitEnum {
  CHECKING_SUBSCRIPTIONS = 'Checking payments',
  FORMING_A_RESPONSE = 'Request processing',
  FORMING_A_FILE = 'Creating a file',
  PROCESSING_THE_RESULTS = 'Preparing the report',
  PROCESSING_SEARCH_RESULTS = 'Analyzing search results',
  WE_SEARCH_FOR_PATENTS = 'Searching for patents worldwide',
  WE_SEARCH_FOR_TRADEMARK = 'Searching for trademarks',
  GETTING_A_LIST_OF_MESSAGES = 'Retrieving a message list',
  DOWNLOADING_THE_FILE = 'Downloading the file',
  CREATE_TASK = 'Creating a task for attorneys',
  CREATE_TASK_OFFERS = 'Requesting offers',
  CREATE_TASK_PRICES = 'Calculating prices',
  WRITE = 'Write',
  HINT_GENERATION = 'Generating hints',
  WAITING_PAYMENT_PATENT = 'WAITING_PAYMENT_PATENT',
  WAITING_PAYMENT_TRADEMARK = 'WAITING_PAYMENT_TRADEMARK',
}
