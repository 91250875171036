export enum TransactionType {
  TRANSFER = 'transfer',
  TRANSFER_IPNOTE = 'transfer_ipnote',
  FEE = 'fee',
  INCOMING_FEE = 'incoming_fee',
  COMPENSATION = 'compensation',
  REFILL = 'refill',
  WITHDRAWAL = 'withdrawal',
  SUBSCRIPTION = 'subscription',
  REFUND = 'refund',
}

export enum TransactionTypes {
  INCOME = 'income',
  EXPENSE = 'expense',
}
