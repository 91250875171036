import { Component, OnInit } from '@angular/core';
import { CompanyTypeEnum, PublicPageSteps } from '@ipnote/enum';
import { EnumsService } from 'app/page-modules/desk/services/enums/enums.service';
import { Store } from '@ngrx/store';
import { AppState } from '#appState';
import linkedInIcon from '@iconify/icons-logos/linkedin-icon';
import googleIcon from '@iconify/icons-logos/google-icon';
import { fadeInUp400ms } from '../../../../../@vex/animations/fade-in-up.animation';
import { Router } from '@angular/router';
import { selectStateUser } from '../../../../store/selectors/user.selectors';
import { StateUser } from '../../../../store/reducers/user';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  animations: [fadeInUp400ms],
})
export class SignUpComponent implements OnInit {
  private readonly linkedInIcon = linkedInIcon;
  private readonly googleIcon = googleIcon;
  public readonly CompanyTypeEnum = CompanyTypeEnum;
  private userState: StateUser;
  companyType: CompanyTypeEnum;
  email: string;
  step: PublicPageSteps = PublicPageSteps.CHOICE_COMPANY_TYPE;
  constructor(public enums: EnumsService, private store: Store<AppState>, private router: Router) {
    this.store
      .select(selectStateUser)
      .pipe(untilDestroyed(this))
      .subscribe((user) => {
        this.userState = user;
      });
  }
  ngOnInit() {
    if (this.userState.aiActions) {
      this.companyType = CompanyTypeEnum.CUSTOMER;
      this.step = PublicPageSteps.PERSONAL_FIELD;
    }
  }

  get joinButtonText(): string {
    let buttonText = 'Create an account';
    if (this.companyType === CompanyTypeEnum.CUSTOMER) {
      buttonText = 'join us as a customer';
    } else if (this.companyType === CompanyTypeEnum.PROVIDER) {
      buttonText = 'join us as a service provider';
    }
    return buttonText;
  }

  choiceType(userType: CompanyTypeEnum) {
    this.companyType = userType;
  }

  changeCompanyType(companyType: CompanyTypeEnum) {
    this.companyType = companyType;
  }
}
