<div @fadeInUp class="overflow-hidden w-full ">
  <div class="flex flex-col items-center">
    <img class="w-[150px] h-[42px]" src="assets/img/new-ipnote-logo.png" alt=""/>
    <div class="text-center my-6">AI powered IP revolution - IP made 5x cheaper and easier</div>
  </div>
  <div [formGroup]="form" fxLayout="column" class="">
    <div fxFlex="auto" fxLayout="column" class="py-0 my-0">
      <mat-form-field class="" fxFlex="grow" appearance="outline">
        <mat-label>{{ 'e-mail' | transloco }}</mat-label>
        <input formControlName="email" matInput required/>
        <!--            <app-form-error [formError]="form.controls['email']?.errors" [showError]="form.invalid && form.touched"></app-form-error>-->
      </mat-form-field>
      <mat-form-field fxFlex="grow" appearance="outline">
        <mat-label>{{ 'password' | transloco }}</mat-label>
        <input (keyup.enter)="send()" [type]="inputType" formControlName="password" matInput required/>
        <button (click)="toggleVisibility()" mat-icon-button matSuffix type="button"
                matTooltip="{{ 'toggle-visibility' | transloco }}">
          <mat-icon class="ic-visibility" *ngIf="visible" [icIcon]="icVisibility"></mat-icon>
          <mat-icon class="ic-visibility" *ngIf="!visible" [icIcon]="icVisibilityOff"></mat-icon>
        </button>
        <!--            <app-form-error [formError]="form.controls['password']?.errors" [showError]="form.invalid && form.touched"></app-form-error>-->
      </mat-form-field>
    </div>
    <div fxLayout="row" class="mb-4" fxLayoutAlign="space-between center">
      <mat-checkbox class="caption text-sm" color="primary" formControlName="save">{{ "remember-me" | transloco }}
      </mat-checkbox>
      <a [routerLink]="['/auth/forgot-password']" class="caption text-sm">{{ "forgot-password" | transloco }}</a>
    </div>
    <button class="btn-icon__spinner" (click)="send()" color="primary" mat-raised-button type="button"
            [disabled]="form.invalid || form.disabled || (signInStore$ | async).waitingServerResponse"
    >
      {{ "Sign in" | transloco }}
      <mat-spinner *ngIf="(signInStore$ | async).waitingServerResponse" [diameter]="25"></mat-spinner>
    </button>

    <app-idp-sign-in></app-idp-sign-in>
    <div fxFlex="auto" fxLayout="row" class="pt-8" fxLayoutAlign="center">
      <span class="pr-1" text-sm>{{ "dont-have-an-account" | transloco }}</span><br/>
      <a class="cursor-pointer" *ngIf="sideNavMode; else elseBlock"
         (click)="stepsHandler.emit(publicPageSteps.PERSONAL_FIELD)">{{ "click-here-to-create-one" | transloco }}</a>
      <ng-template #elseBlock>
        <a class="text-sm" [routerLink]="['/auth/sign-up']">{{ "click-here-to-create-one" | transloco }}</a>
      </ng-template>
    </div>
  </div>
</div>

